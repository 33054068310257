<ng-container *ngIf="link === undefined || link.length === 0; else useLink">
    <div class="side-nav-item" [ngClass]="{'closed': (navService.sideNavCollapsed$ | async), 'active': highlighted}">
        <ng-container [ngTemplateOutlet]="inner"></ng-container>
    </div>
</ng-container>

<ng-template #useLink>
    <a class="side-nav-item" href="javascript:void(0);" [ngClass]="{'closed': (navService.sideNavCollapsed$ | async), 'active': highlighted}" [routerLink]="link">
        <ng-container [ngTemplateOutlet]="inner"></ng-container>
    </a>
</ng-template>


<ng-template #inner>
    <div class="active-highlight"></div>
    <span class="phone-hidden" title="{{title}}">
        <div>
            <i class="fa {{icon}}" aria-hidden="true"></i>
        </div>
    </span>
    <span class="side-nav-text">
        <div>
            {{title}}
        </div>
    </span>
    <span class="card-actions">
        <ng-content select="[actions]"></ng-content>
    </span>
</ng-template>